@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@300;400;700&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
  position: relative;
  
  background-color: #333333;
  font-family: 'Prompt', 'Libre askerville';


  flex: 1;
  display: flex;
  justify-content: center;
  @apply text-base;
}

.formInput {
  @apply border border-inherit rounded-md p-2 mr-0.5;
}

html {
  scroll-behavior: smooth;
}



.bodyContainer {
  width: 100%;
  flex:1;
  justify-content: center;


}

/* Set max-width based on screen size */
@media (min-width: 640px) {
  .bodyContainer {

  }
}

@media (min-width: 768px) {
  .bodyContainer {

  }
}

@media (min-width: 1060px) {
  .bodyContainer {

  }
}

@media (min-width: 1280px) {
  body {
    
    max-width: 1380px;
    margin: auto;
  }
}